import React from "react";
import Countdown from "react-countdown";
import PresaleMethods from "../TopSliders/PresaleMethods";

const CountDownTwo = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hours
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minutes
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>seconds
          </div><br />
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hours
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minutes
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>seconds

          </div>
          <br />
        </>
      );
    }
  };

  var countDownDate = new Date("Nov 20, 2024 12:00:00").getTime();

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-12">
            <div className="countdown-wrap cdmain">
              {/* <h2 className="title">Mainnet release:</h2> */}
              <div id="countdown-gampang"></div>

              <div className="custom-countdown-two">
                {/* <Countdown date={Date.now() + 1729130400} renderer={renderer} /> */}
                {/* <h6>Teir 2: 6% Bonus</h6> <br></br> */}
                {/* <Countdown date={countDownDate} renderer={renderer} /> */}
                {/* <h6>Teir 2: 6% Bonus</h6> */}
                {/* <h2 className="title">Until Price increase</h2>  */}

                <div className="row">
                <Countdown date={countDownDate} renderer={renderer} />
                </div>
                
                {/* <Countdown date={countDownDate} renderer={renderer} />  */}
                <div className="row">



                  <h2 className="title">Until Price increase  </h2> 
                  <h6>Tier 3: 4% Bonus</h6>
                </div>

                {/* <h6>Teir 2: 6% Bonus</h6> */}

                {/* <h6>Teir 2: 6% Bonus</h6> */}
                {/* <PresaleMethods /> */}

              </div>
              {/* <div className="inlinebtn">
                    <button style={{ marginRight: "20px" }} className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div> */}
            </div>

          </div>

        </div>
      </div>

    </section>
  );
};

export default CountDownTwo;
